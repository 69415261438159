import React, { useContext } from "react";
import { SttTranslateHook } from '@stt-componentes/core';
import usuario from "../signals/usuario";
import { useSignal } from "@preact/signals-react";
import { SttExibicaoMensagem, } from "@stt-componentes/atendimento";
import { EVENTOS_SOCKET } from "../common/Constants";
import socket from "../signals/socket";
import { atendimentoEncerrado, idAtendimento, idSalaAtendimento, mensagemResposta } from "../signals/atendimentos";

const ExibicaoMensagemContainer = ({ mensagens = [], ehHistorico = false }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    useSignal();

    const excluirMensagem = (msg) => {
        const dadosExcluir = {
            idMensagemExcluida: msg._id,
            idSala: idSalaAtendimento.value,
        }
        if (msg.anexo) {
            dadosExcluir.idAnexo = msg.anexo._id;
            dadosExcluir.idAtendimento = idAtendimento.value;
        }
        socket.value.emit(EVENTOS_SOCKET.EXCLUIR_MENSAGEM, JSON.stringify(dadosExcluir));
    }

    const alterarMensagem = (mensagemNova, idMensagem) => {
        const dados = JSON.stringify({
            idMensagemAlterada: idMensagem,
            idSala: idSalaAtendimento.value,
            mensagemNova
        });
        socket.value.emit(EVENTOS_SOCKET.ALTERAR_MENSAGEM, dados);
    }

    const esconderCampos = (msg, msgAnterior) => {
        const proprioUsuarioEnvio = usuario.value.idFuncionario === msg.responsavel?.id;
        let esconderNomeEnviador = false;
        if (msgAnterior?.responsavel && msg?.responsavel) {
            esconderNomeEnviador = msgAnterior.responsavel.id === msg.responsavel.id;
        }

        return {
            esconderNomeEnviador,
            proprioUsuarioEnvio
        }
    }

    return (
        <SttExibicaoMensagem
            strings={strings}
            excluirMensagem={excluirMensagem}
            alterarMensagem={alterarMensagem}
            esconderCampos={esconderCampos}
            mensagemResposta={mensagemResposta}
            atendimentoEncerrado={atendimentoEncerrado}
            mensagens={mensagens}
            ehHistorico={ehHistorico}
        />
    );
};

export default ExibicaoMensagemContainer;