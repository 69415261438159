
export const CONSTANTES = {
    SEM_RESULTADO: 0,
    EXTENSAO_IMAGEM_JPG: 'jpg',
    EXTENSAO_IMAGEM_JPEG: 'jpeg',
    EXTENSAO_IMAGEM_PNG: 'png',
    EXTENSAO_IMAGEM_BMP: 'bmp',

    PERFIL: {
        ATENDENTE_HELPDESK: 'atendHelpdesk',
        ATENDENTE_HELPDESK_ESTADUAL: 'atendHelpdeskEstadua'

    },
    PERMISSOES: {
        HELPDESK: 'HELPDESK_HELPDESK',
        ATENDIMENTO: 'HELPDESK_ATENDIMENTO',
    }
};

export const VINCULO_PERFIL_ESTADUAL = 'E';

export const EVENTOS_SOCKET = {
    ALTERAR_MENSAGEM: 'alterar_mensagem',
    ALTERAR_STATUS_ATENDIMENTO_VIDEO: 'alterar_status_atendimento_video',
    ATENDENTE_DESCONECTADO: 'atendente_desconectado',
    ATENDENTE_ONLINE: 'atendente_online',
    ATENDIDO_DESCONECTADO: 'atendido_desconectado',
    ATENDIMENTO_ENCERRADO: 'atendimento_encerrado',
    AVISO_DIGITANDO: 'aviso_digitando',
    CONNECTION: 'connection',
    DADOS_USUARIO: 'dados_usuario',
    DISCONNECT: 'disconnect',
    ENCAMINHAR_ATENDIMENTO: 'encaminhar_atendimento',
    ENTRAR_SALA_ATENDIMENTO: 'entrar_sala_atendimento',
    ENVIAR_ANEXO: 'enviar_anexo',
    ENVIAR_AVISO_DIGITANDO: 'enviar_aviso_digitando',
    ENVIAR_MENSAGEM: 'enviar_mensagem',
    EXCLUIR_MENSAGEM: 'excluir_mensagem',
    FINALIZAR_ATENDIMENTO: 'finalizar_atendimento',
    ID_SALA_ATENDIMENTO: 'id_sala_atendimento',
    INICIAR_ATENDIMENTO: 'iniciar_atendimento',
    LISTA_ATENDENTES: 'lista_atendentes',
    LISTA_PARA_ATENDIMENTO: 'lista_para_atendimento',
    MENSAGEM_ALTERADA: 'mensagem_alterada',
    MENSAGEM_ENVIADA: 'mensagem_enviada',
    MENSAGEM_EXCLUIDA: 'mensagem_excluida',
    MENSAGENS_SALA: 'mensagens_sala',
    NOTIFICACAO_SOLICITACAO_ATENDIMENTO: 'notificacao_solicitacao_atendimento',
    SAIR_ATENDIMENTO: 'sair_atendimento',
    SOLICITAR_ATENDIMENTO: 'solicitar_atendimento',
    SOLICITAR_NOVO_ATENDIMENTO: 'solicitar_novo_atendimento',
    STATUS_ATENDIMENTO_VIDEO: 'status_atendimento_video',
    STATUS_ATENDIMENTO_VIDEO_INICIAL: 'status_atendimento_video_inicial'
}

const situacaoAtendimento = {
    ESPERA: 0,
    SOLICITOU_ATENDIMENTO: 5,
    EM_ATENDIMENTO: 10,
    ENCAMINHADO: 15,
    CONCLUIDO: 20,
    HISTORICO: 25
}

export const SITUACAO_ATENDIMENTO = {
    SITUACAO: { ...situacaoAtendimento },
    CHIP: {
        [situacaoAtendimento.ESPERA]: {
            descricao: 'Disponível',
            cor: '#FFE599'
        },
        [situacaoAtendimento.SOLICITOU_ATENDIMENTO]: {
            descricao: 'Solicitado',
            cor: '#E06666'
        },
        [situacaoAtendimento.EM_ATENDIMENTO]: {
            descricao: 'Ativo',
            cor: '#70C1B3'
        },
        [situacaoAtendimento.ENCAMINHADO]: {
            descricao: 'Encaminhado',
            cor: '#92A8D1'
        },
        [situacaoAtendimento.CONCLUIDO]: {
            descricao: 'Concluído',
            cor: '#D9D9D9'
        },
        [situacaoAtendimento.HISTORICO]: {
            descricao: 'Histórico',
            cor: '#6D6875'
        },

    }
}